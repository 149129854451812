<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        class="mr-3"
        @click="download"
        v-if="selectedRowKeys.length"
        icon="download"
        v-has="'system:font:list:button:download'"
        >下载</a-button
      >
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'system:font:list:button:delete'"
        >删除</a-button
      >
      <a-button type="primary" @click="openAddForm" v-has="'system:font:list:button:add'" icon="plus">新建</a-button>
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="loadData">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="displayName" placeholder="请输入字体名称" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="loadData">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- table区域-begin -->
    <div>
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        v-has="'system:font:list:page:view'"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: '1200px', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
        @change="handleTableChange"
      >
        <span slot="fontUsages" slot-scope="text">
          {{ text && text.join(',') }}
        </span>

        <span slot="antiAliasMethod" slot-scope="text">
          {{ getAntiAliasMethod(text) }}
        </span>

        <span slot="languages" slot-scope="text">
          {{ text && text.join(',') }}
        </span>
        <span slot="fontpreview" :style="{ fontFamily: record.showFontFamily }" slot-scope="text, record">
          <span v-if="record.languages == '日语'">亜あアAa123</span>
          <span v-else>漫享创界comijoy2017</span>
          <!--           <a-spin :spinning="textLoading">
            <v-stage :config="configKonva">
              <v-layer>
                <v-text text="漫享创界comijoy2017" :fontFamily="record.fontFamily" :y="10" :fontSize="18"></v-text>
              </v-layer>
            </v-stage>
          </a-spin> -->
        </span>
        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">
            {{ one.departName }}
          </a-tag>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <font-modal :open="drawerShow" :cur-row="curRow" :isForm="isNewForm" @closed="drawerClosed"></font-modal>
  </body-layout>
</template>

<script>
import FontModal from './modules/font/font-modal'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { getAction } from '@/api/manage'
import { batchStyleInsertHeader } from '@/utils/util'
import JInput from '@/components/jeecg/JInput'
import JSZip from 'jszip'
import axios from 'axios'
import FileSaver from 'file-saver'
export default {
  name: 'Font',
  mixins: [JeecgListMixin],
  components: { FontModal, JInput },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      getDepartIdsData: [],
      displayName: '',
      configKonva: {
        width: 200,
        height: 30
      },
      configCircle: {
        x: 100,
        y: 100,
        radius: 70,
        fill: 'red',
        stroke: 'black',
        strokeWidth: 4
      },
      textLoading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 45,
          sorter: false
        },
        {
          title: '字体中文名',
          dataIndex: 'displayName',
          width: 120,
          sorter: false
        },
        /*         {
          title: '字体英文名',
          dataIndex: 'englishName',
          width: 100,
        }, */
        /*         {
          title: '字体系列',
          dataIndex: 'serialName',
          width: 100,
        }, */
        /*         {
          title: '抗锯齿',
          dataIndex: 'antiAliasMethod',
          scopedSlots: { customRender: 'antiAliasMethod' },
          width: 80,
        }, */
        {
          title: '字体用途',
          dataIndex: 'fontUsages',
          scopedSlots: { customRender: 'fontUsages' },
          width: 100
        },
        {
          title: '字体样式',
          dataIndex: 'styleName',
          scopedSlots: { customRender: 'styleName' },
          width: 100
        },

        {
          title: '适用语言',
          scopedSlots: { customRender: 'languages' },
          dataIndex: 'languages',
          width: 100
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 120
        },
        {
          title: '字体预览',
          dataIndex: 'fontFamily',
          scopedSlots: { customRender: 'fontpreview' },
          width: 200
        }
      ],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/fonts/list',
        delete: '/fonts/delete',
        deleteBatch: 'fonts/delete_batch'
      },
      isInserstCss: true
    }
  },
  computed: {},
  mounted() {
    this.textLoading = true
    setTimeout(() => {
      this.configKonva = {
        width: 210,
        height: 30
      }
      this.textLoading = false
    }, 1000)
  },
  methods: {
    download() {
      this.downImg(this.selectionRows)
    },
    handleMenuClick(e) {
      if (e.key == 1) {
        this.batchDel()
      } else if (e.key == 2) {
        this.batchFrozen(2)
      } else if (e.key == 3) {
        this.batchFrozen(1)
      }
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      if (this.url.list.path) {
        params.current = params.pageNo
        params.size = params.pageSize
        params.displayName = this.displayName
        this.domain && (params.domain = this.domain)
        getAction(this.url.list.path, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            this.selectedRowKeys = []
            this.selectionRows = []
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

            // 字体预览需要往head里插入css样式
            if (this.isInserstCss) {
              batchStyleInsertHeader(this.dataSource)
            }
            this.$nextTick(() => {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            })
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
          this.loading = false
        })
      } else {
        params.current = params.pageNo
        params.size = params.pageSize
        params.displayName = this.displayName
        getAction(this.url.list, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            this.selectedRowKeys = []
            this.selectionRows = []
            //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

            // 字体预览需要往head里插入css样式
            if (this.isInserstCss) {
              res.data.records.forEach(item => {
                item.showFontFamily = 'font' + item.fontFamily
              })
              let fontArr = JSON.parse(JSON.stringify(res.data.records))
              fontArr.forEach(item=>{
                item.fontFamily=item.showFontFamily
              })
              batchStyleInsertHeader(fontArr)
              setTimeout(() => {
                this.dataSource = res.data.records || res.data

                if (res.data.total) {
                  this.ipagination.total = res.data.total - 0
                } else {
                  this.ipagination.total = 0
                }
                this.$emit('loadData', this.dataSource)
              }, 0)
            } else {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            }
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
          this.loading = false
        })
      }
    },
    downImg(fileList) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      fileList.forEach((item, i) => {
        const promise = this.getFile(item).then(data => {
          // 下载文件, 并存成blob对象
          const file_name = i + item.fontUrl.substring(item.fontUrl.lastIndexOf('/') + 1) // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
          FileSaver.saveAs(content, '批量下载.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    getFile(item) {
      let url = item.fontUrl
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    getAntiAliasMethod(method) {
      let config = [
        {
          value: 'antiAliasStrong',
          label: '浑厚'
        },
        {
          value: 'antiAliasSharp',
          label: '锐利'
        },
        {
          value: 'antiAliasCrisp',
          label: '犀利'
        },
        {
          value: 'antiAliasSmooth',
          label: '平滑'
        }
      ]
      for (let idx = 0; idx < config.length; idx++) {
        if (config[idx].value == method) {
          return config[idx].label
        }
      }
      return '锐利'
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~@assets/less/common.less';
/deep/ .border-bottom {
  border: none;
}
</style>
